<script setup>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
import Read from '@/assets/ic_read_normal.png';
import Scene1 from '@/assets/scene1.png';
import G1 from '@/assets/g1.png';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { isSafari, setVhProperty, observeFun } from '@/utils/index';

import { onBeforeUnmount, onMounted, ref, computed } from 'vue';

import { useI18n } from 'vue-i18n';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const { t, locale } = useI18n();
const currentIndex = ref(0);
let resetTimer = null;
const modules = ref([Autoplay]);
const isScroll = ref('');
const mySwiper = ref(null);
const mySwiper1 = ref(null);
const showBgcBar = ref(false);
const showNavBar = ref(true);
const dynamicStyle = ref();
const fadeClass = ref('');
const sections = ref(['title-1']);
const progressBar = ref(null);
let observers = [];
const activeSection = ref([]);
const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const onAutoplayTimeLeft = (s, time, progress) => {
  console.log(s, progress);
  const maxWidth = 380; // 进度条的最大宽度
  const percentage = time / 6000; // 计算进度百分比
  // 动态调整进度条的宽度
  progressBar.value.style.width = `${maxWidth * (1 - percentage)}px`;

  // 当进度条填满时，等待3秒后重置
  if (percentage >= 1) {
    // 清除之前的定时器
    if (resetTimer) {
      clearTimeout(resetTimer);
    }

    // 延时3秒后重置进度条宽度
    resetTimer = setTimeout(() => {
      progressBar.value.style.width = '0px'; // 重置进度条宽度
    }, 6000); // 等待3秒后重置
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = document
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = () => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};

const onSwiper = (swiper) => {
  mySwiper.value = swiper; // Swiper初始化时设置实例
};

const onSwiper1 = (swiper) => {
  mySwiper1.value = swiper; // Swiper初始化时设置实例
};
// const pauseAutoplay = () => {
//   if (mySwiper.value) {
//     mySwiper.value.autoplay.stop(); // 停止自动播放
//   }
// };

// const startAutoplay = () => {
//   if (mySwiper.value) {
//     mySwiper.value.autoplay.start(); // 停止自动播放
//   }
// };

// const pauseHandle = () => {
//   if (mySwiper1.value) {
//     mySwiper1.value.autoplay.stop(); // 停止自动播放
//   }
// };

// const startHandle = () => {
//   if (mySwiper1.value) {
//     mySwiper1.value.autoplay.start(); // 停止自动播放
//   }
// };

const slideChange = (swiper) => {
  // 获取当前的索引
  const newIndex = swiper.realIndex; // 如果是 loop 模式，获取实际的索引

  // 如果当前的索引和上次的索引不同，才执行
  if (currentIndex.value !== newIndex) {
    console.log('Slide changed:', newIndex);
    currentIndex.value = newIndex; // 更新索引
  }
};

const intGsap = () => {
  gsap.registerPlugin(ScrollTrigger);
  // 选择所有的 .slide 元素
  const slides = document.querySelectorAll('.slide');

  // 创建时间轴实例
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.scene',
      start: 'top',
      end: '+=300%', // 增加滚动范围
      scrub: 1, // 调整为更大的值使滚动更慢
      pin: true,
      markers: false,
      toggleActions: 'play reverse play reverse',
    },
  });
  setSlideGsap(tl);
  setTitleGsap(tl);
};
const setTitleGsap = (tl) => {
  tl.add(
    gsap.fromTo(
      '.scene-title1',
      { display: 'block', index: 1 },
      {
        index: 0,
        display: 'none',
        duration: 2,
        ease: 'none',
      }
    ),
    0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  )
    .add(
      gsap.fromTo(
        '.scene-title2',
        { display: 'none', index: 0 },
        {
          index: 0,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.scene-title2',
        { display: 'block', index: 1 },
        {
          index: 0,
          display: 'none',
          duration: 2,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.scene-title3',
        { display: 'none', index: 0 },
        {
          index: 0,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.scene-title3',
        { display: 'block', index: 1 },
        {
          index: 0,
          display: 'none',
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.scene-title4',
        { display: 'none', index: 0 },
        {
          index: 1,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      6 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    );
};
const setSlideGsap = (tl) => {
  tl.add(
    gsap.fromTo(
      '.slide1',
      { display: 'block', index: 1 },
      {
        index: 0,
        display: 'none',
        duration: 2,
        ease: 'none',
      }
    ),
    0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  )
    .add(
      gsap.fromTo(
        '.slide2',
        { display: 'none', index: 0 },
        {
          index: 0,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.slide2',
        { display: 'block', index: 1 },
        {
          index: 0,
          display: 'none',
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.slide3',
        { display: 'none', index: 0 },
        {
          index: 0,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.slide3',
        { display: 'block', index: 1 },
        {
          index: 0,
          display: 'none',
          duration: 2,
          ease: 'none',
        }
      ),
      6 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.slide4',
        { display: 'none', index: 0 },
        {
          index: 1,
          display: 'block',
          duration: 2,
          ease: 'none',
        }
      ),
      6 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    );
};
onMounted(() => {
  setStyle();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
  // 滚动到标题添加动画
  observeFun(sections, activeSection, observers);

  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
  intGsap();
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
});
</script>

<template>
  <div class="product" :class="{ 'product-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div class="product-banner" id="banner" :style="dynamicStyle">
      <img
        src="https://oss-cn-beijing.galbot.com/online/portal/img/product/Group1.png"
        alt=""
      />
      <div class="text">
        <div class="text-div" :class="fadeClass">
          <div>
            <span>{{ t('product.banner[0]') }}</span>
          </div>
          <div>{{ t('product.banner[1]') }}</div>
          <div>{{ t('product.banner[2]') }}</div>
          <div>{{ t('product.banner[3]') }}</div>
          <div class="contact" @click="isScroll = new Date().getTime()">
            {{ t('product.contact') }}
            <img :src="Read" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="product-main">
      <div class="main-item">
        <swiper
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :autoplay="{
            delay: 6000,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper"
          @swiper="onSwiper"
          @autoplayTimeLeft="onAutoplayTimeLeft"
        >
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper2.png"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[0].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[0].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper1.png"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[1].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[1].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper3.png"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[2].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[2].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <template #container-end>
            <div class="autoplay-progress">
              <svg viewBox="0 0 300 2">
                <defs>
                  <linearGradient
                    id="progress-gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style="stop-color: #a264ff; stop-opacity: 1"
                    />
                    <stop
                      offset="25%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="50%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="100%"
                      style="stop-color: #cef7ff; stop-opacity: 1"
                    />
                  </linearGradient>
                </defs>
                <rect x="0" y="0" width="300" height="2" fill="#e0e0e0" />
                <rect
                  x="0"
                  ref="progressBar"
                  y="0"
                  width="0"
                  height="2"
                  fill="url(#progress-gradient)"
                  class="progress-bar"
                />
              </svg>
            </div>
          </template>
        </swiper>
      </div>
      <div class="main-item scene">
        <div class="scene-title1">
          <div class="title" id="title-1">
            <div :class="{ 'fade-up': activeSection.includes('title-1') }">
              {{ t(`product.logistics[0].title`) }}
            </div>
          </div>
          <div
            class="subtitle"
            :class="{ 'fade-up': activeSection.includes('title-1') }"
          >
            {{ t(`product.logistics[0].decs`) }}
          </div>
        </div>
        <div class="scene-title2">
          <div class="title" id="title-1">
            <div :class="{ 'fade-up': activeSection.includes('title-1') }">
              {{ t(`product.logistics[1].title`) }}
            </div>
          </div>
          <div
            class="subtitle"
            :class="{ 'fade-up': activeSection.includes('title-1') }"
          >
            {{ t(`product.logistics[1].decs`) }}
          </div>
        </div>
        <div class="scene-title3">
          <div class="title" id="title-1">
            <div :class="{ 'fade-up': activeSection.includes('title-1') }">
              {{ t(`product.logistics[2].title`) }}
            </div>
          </div>
          <div
            class="subtitle"
            :class="{ 'fade-up': activeSection.includes('title-1') }"
          >
            {{ t(`product.logistics[2].decs`) }}
          </div>
        </div>
        <div class="scene-title4">
          <div class="title" id="title-1">
            <div :class="{ 'fade-up': activeSection.includes('title-1') }">
              {{ t(`product.logistics[3].title`) }}
            </div>
          </div>
          <div
            class="subtitle"
            :class="{ 'fade-up': activeSection.includes('title-1') }"
          >
            {{ t(`product.logistics[3].decs`) }}
          </div>
        </div>
        <div class="slides-wrapper">
          <div class="slide slide1">
            <div class="img">
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/slide1.png"
                alt=""
              />
            </div>
          </div>
          <div class="slide slide2">
            <div class="img">
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/slide2.png"
                alt=""
              />
            </div>
          </div>
          <div class="slide slide3">
            <div class="img">
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/slide3.png"
                alt=""
              />
            </div>
          </div>
          <div class="slide slide4">
            <div class="img">
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/slide4.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="main-item bg">
        <div class="main-item-center">
          <div class="content">
            <img
              src="https://oss-cn-beijing.galbot.com/test/portal/img/product/g1.png"
            />
            <div class="content-info">
              <span>G1</span>
              <span>{{ t('product.section2[0].span') }}</span>
              <div>
                {{ t('product.section2[1].span') }}
                <span>{{ t('product.section2[1].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[2].span') }}
                <span>{{ t('product.section2[2].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[3].span') }}
                <span>{{ t('product.section2[3].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[4].span') }}
                <span>{{ t('product.section2[4].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[5].span') }}
                <span>{{ t('product.section2[5].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[6].span') }}
                <span>{{ t('product.section2[6].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[7].span') }}
                <span>{{ t('product.section2[7].unit') }}</span>
              </div>
              <div>
                {{ t('product.section2[8].span') }}
                <span>{{ t('product.section2[8].unit') }}</span>
                {{ t('product.section2[8].span1') }}
              </div>

              <div class="contact-us" @click="isScroll = new Date().getTime()">
                {{ t('product.contact') }}
                <img :src="Read" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-footer">
        <Footer :isScroll="isScroll" @onDefault="isScroll = ''" />
      </div>
    </div>
    <FooterCookie />
  </div>
</template>

<style lang="scss" scoped>
.product {
  width: 100%;
  &-banner {
    position: relative;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    img {
      width: 100%;
      // opacity: 0.8;
      // height: 100%;
    }
    .text {
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      position: absolute;
      top: 0px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      > div {
        position: absolute;
        max-width: 1450px;
        color: #fff;
        font-family: MyRegular;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        // line-height: 45px; /* 112.069% */
        bottom: 10%;
        opacity: 0;
        transform: translateY(80%);
        transition: opacity 1.5s, transform 1s;
        span {
          font-size: 100px;
          font-family: Arial;
          font-weight: bold;
        }
        > div {
          margin-bottom: 10px;
        }
        .contact {
          margin-top: 26px;
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #fff;
          font-family: MyRegular;
          font-size: 32px;
          font-style: normal;
          font-weight: 300;
          img {
            width: 48px;
            height: 48px;
            margin-left: 15px;
          }
        }
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    .swiper {
      width: 100%;
      height: 100%;

      .autoplay-progress {
        position: absolute;
        left: 85px;
        bottom: 68px;
        width: 300px;
        height: 2cap;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: red;
      }

      .autoplay-progress svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .progress-bar {
        transition: width 0.2s ease-in-out; /* Smooth transition for width change */
      }
    }
    .swiper-slide {
      width: 100%;
      text-align: center;
      font-size: 18px;
      /* Center slide text vertically */
      display: flex;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .slide-right {
          text-align: left;
          width: 695px;
          position: absolute;
          top: 65%;
          transform: translateY(-50%);
          z-index: 10;
          left: 85px;
          // transition: right 1s ease-in-out;
          &-title {
            font-family: 'Arial';
            display: block;
            font-size: 58px;
            color: #000;
            margin-bottom: 20px;
          }
          &-decs {
            font-family: MyRegular;
            line-height: 40px;
            font-size: 400px;
            font-size: 20px;
            color: #444;
          }
        }
        .active {
          right: 75px;
        }
      }
      .img {
        position: relative;
        top: 2%;
        margin: 0 auto;
        width: 100%;
        padding: 0 60px;
        box-sizing: border-box;
        img {
          display: block;
          margin: 0 auto;
          width: 100%; /* 图片宽度自适应父级元素 */
          max-width: 2220px;
          max-height: 88%;
          height: auto; /* 高度自适应宽度 */
          object-fit: contain; /* 保持图片的宽高比，同时覆盖父级元素的区域 */
        }
      }
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .main-item {
      position: relative;
      width: 100%;
      width: 100%;
      max-height: calc(var(--vh, 1vh) * 100);
      min-height: 980px;
      aspect-ratio: 16 / 9;
      .slides-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 120px);
        display: flex;
        position: sticky;
        .slide1,
        .slide2,
        .slide3,
        .slide4 {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          overflow: hidden;
          z-index: 0;
          display: none;
          .img {
            margin: 0 auto;
            width: 87.5%;
            img {
              width: 100%;
            }
          }
        }
        .slide1 {
          z-index: 1;
          display: block;
        }
        .slide2 {
        }
        .slide3 {
        }
        .slide4 {
        }
      }
      &-center {
        width: 82%;
        height: 100%;
        position: relative;
        margin: 0 auto;
      }
      .title {
        color: #fff;
        font-family: Arial;
        font-size: 68px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        margin-bottom: 10px;
        > div {
          opacity: 0;
          transform: translateY(110%);
          transition: opacity 1s, transform 0.5s;
        }
      }
      .subtitle {
        height: 30px;
        margin-top: 12px;
        color: #fff;
        font-family: MyRegular;
        font-size: 23px;
        text-align: center;
        line-height: 30px;
        font-weight: 600;
        opacity: 0;
        transform: translateY(110%);
        transition: opacity 1s, transform 0.5s;
      }
      .fade-up {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
      .content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 100%;
        margin: 0 auto;
        > img {
          height: 865px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        .content-info {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          > span:first-child {
            position: relative;
            left: -15px;
            font-family: Arial Hebrew Scholar, Arial;
            font-size: 220px;
            line-height: 80px;
            margin-bottom: 36px;
            color: #212121;
            &::after {
              height: 0;
            }
          }
          > span:nth-child(2) {
            font-family: Arial Hebrew Scholar, Arial;
            font-size: 40px;
            margin-bottom: 36px;
            color: #212121;
            &::after {
              height: 0;
            }
          }
          div {
            padding-left: 22px;
            position: relative;
            font-family: MyRegular;
            font-size: 26px;
            color: rgba(65, 65, 65, 1);
            line-height: 52px;
            &::after {
              position: absolute;
              top: 23px;
              left: 0px;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 10px;
              background: #83a7ff;
            }
            span {
              position: relative;
              font-family: MyRegular;
              font-size: 26px;
              color: rgba(65, 65, 65, 1);
              line-height: 52px;
            }
            &:last-child {
              &::after {
                width: 0;
              }
            }
          }
          .contact-us {
            position: absolute;
            left: -22px;
            bottom: -133px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #414141;
            font-family: MyRegular;
            font-size: 28px;
            font-style: normal;
            font-weight: 300;
            img {
              width: 48px;
              height: 48px;
              margin-left: 17px;
            }
          }
        }
      }
    }
    .bg {
      position: relative;
      background: url('https://oss-cn-beijing.galbot.com/test/portal/img/product/bgc.png')
        no-repeat;
      background-size: cover;
    }
    .scene {
      padding-top: 60px;
      box-sizing: border-box;
      .scene-title1,
      .scene-title2,
      .scene-title3,
      .scene-title4 {
        overflow: hidden;
        z-index: 0;
        display: none;
      }
      .scene-title1 {
        z-index: 1;
        display: block;
      }
    }
  }
  .main-footer {
    max-width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}
.product-zh {
  .content {
    .content-info {
      > span:first-child {
        font-family: Arial Hebrew Scholar, Arial;
      }
      > span:nth-child(2) {
        font-family: Arial Hebrew Scholar, Arial;
        font-size: 40px;
        margin-bottom: 36px;
        color: #212121;
      }
      div {
        font-family: MyRegular;
        span {
          font-family: Arial;
        }
      }
    }
  }
}
@media (min-width: 1920px) and (max-width: 2100px) {
  .main-item {
    &-center {
      width: 88% !important;
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 960px) and (max-width: 1920px) {
  .product {
    &-main {
      .swiper-slide {
        .img {
          > img {
            max-height: 83%;
          }
        }
      }
      .main-item {
        &-center {
          width: 88% !important;
        }
        .content {
          > img {
            height: 72%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          > div {
          }
        }
      }
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 959px) {
  .product {
    &-main {
      .main-item {
        &-center {
          width: 90% !important;
        }
        .content {
          > img {
            height: 55%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          > div {
          }
        }
      }
    }
    &-banner {
      .text {
        // max-width: 85%;
        div {
          span {
          }
          // width: 88%;
          font-size: 42px;
        }
      }
    }
  }
}
</style>
